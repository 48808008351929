<template>
  <span class="secondary--text fs-14px" v-if="parsedValue.Action == 'AcceptResourceOffer'">
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-circle-check ml-2 mr-1"></i>
        <b><u>Offer Accepted</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'DeclineResourceOffer'">
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-ban ml-2 mr-1"></i>
        <b><u>Offer Declined</u></b>
      </span>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly mt-2 ml-2"
        style="border-left: 3px solid #607d8b"
        v-if="parsedValue.Note != null && parsedValue.Note != ''"
        v-html="parsedValue.Note"
      ></div>
    </v-alert>
  </span>
  <span
    class="secondary--text fs-14px"
    v-else-if="parsedValue.Action == 'SendResourceOfferToTarget'"
  >
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-paper-plane ml-2 mr-1"></i>
        <b><u>Offer Sent to Target User</u></b>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'ApproveResourceOffer'">
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-check-double ml-2 mr-1"></i>
        <b><u>Offer Approved</u></b>
      </span>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly mt-2 ml-2"
        style="border-left: 3px solid #607d8b"
        v-if="parsedValue.Note != null && parsedValue.Note != ''"
        v-html="parsedValue.Note"
      ></div>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'RejectResourceOffer'">
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-power-off ml-2 mr-1"></i>
        <b><u>Offer Rejected</u></b>
      </span>
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly mt-2 ml-2"
        style="border-left: 3px solid #607d8b"
        v-if="parsedValue.Note != null && parsedValue.Note != ''"
        v-html="parsedValue.Note"
      ></div>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'DeleteResourceOffer'">
    <v-alert color="blue-grey" dense text class="my-1 px-2">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-trash-alt ml-2 mr-1"></i>
        <b><u>Offer Deleted</u></b>
      </span>
    </v-alert>
  </span>
</template>

<script>
export default {
  components: {},
  name: "related-actions-logs",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
