<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="slideout-deep-purple-header"
    :visible.sync="slideouts.review.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Offer ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span><i class="fad fa-horse-head mr-2"></i>Review: {{ selected.name }}</span>
        <resource-offer-state class="ml-2" :state="selected.state"></resource-offer-state>
      </h3>
      <div>
        <panel-size-control dark v-model="slideouts.review.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs
        v-model="slideouts.review.tab"
        color="error"
        v-if="slideouts.review.active && !isFetching"
      >
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1">
          <i class="fad fa-clock mr-2"></i>Offered Hours
          <v-chip
            :color="slideouts.review.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ totalOfferedHours }}
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Offer ...</p>
    </v-container>

    <v-tabs-items v-model="slideouts.review.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-container fluid class="pa-4">
          <v-row dense>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                label="Name"
                placeholder="Offer Name"
                v-model="selected.name"
                readonly
              ></v-text-field-alt>
            </v-col>
            <v-col cols="12" md="12" class="mb-4">
              <rich-text-editor
                ref="richTextEditor"
                v-model="selected.justification"
                title="Offer Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
                showLabel
                allowExpand
                readonly
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%">
          <div style="height: 100%; display: flex; flex-direction: column">
            <v-row class="mt-0">
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Take hours from
                  <span class="opacity-64 fs-12px">"Source"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.sourceUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Offer hours to
                  <span class="opacity-64 fs-12px">"Target"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
            </v-row>
            <!-- <v-divider class="mt-4"></v-divider> -->
            <div class="mt-6 mb-0 d-flex flex-row align-center" style="gap: 1rem">
              <label class="input-label">
                <i class="fad fa-clock mr-2"></i>Offered Hours
                <v-chip
                  color="secondary"
                  dark
                  class="ml-2 font-weight-bold"
                  small
                  label
                  style="height: 20px"
                  >{{ totalOfferedHours }}
                </v-chip>
              </label>
            </div>
            <div
              class="pa-5 inset-shadow mt-2"
              style="
                background: #eceff1;
                border-radius: 0.5rem;
                overflow: auto;
                overflow: overlay;
                height: 100%;
              "
            >
              <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
                <div
                  v-if="selected.offerRecords != null && selected.offerRecords.length == 0"
                  key="empty"
                  style="gap: 1rem"
                  class="d-flex align-center justify-center flex-column"
                >
                  <i class="fad fa-horse-saddle fa-swap-opacity" style="font-size: 7rem"></i>
                  <h3 class="mb-1">Nothing to offer yet!</h3>
                </div>
                <v-slide-y-transition
                  v-else
                  key="not-empty"
                  mode="out-in"
                  group
                  style="width: 100%; gap: 1rem"
                  class="d-flex flex-column"
                >
                  <offer-record-card
                    v-for="(offerRecord, i) in selected.offerRecords"
                    :key="'or_' + i"
                    :value="selected.offerRecords[i]"
                    readonly
                    @delete="deleteOfferRecord(offerRecord)"
                    fullWidth
                  ></offer-record-card>
                </v-slide-y-transition>
              </v-slide-y-reverse-transition>
            </div>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="modals.decline.active"
      max-width="800px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-ban mr-2"></i>Decline Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.decline.valid" ref="declineOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.decline.note"
                    title="Decline Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="declineOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="error"
              :dark="validDeclineNotes && !modals.decline.loading"
              :disabled="!validDeclineNotes || modals.decline.loading"
              :loading="modals.decline.loading"
              @click="declineOfferConfirmed()"
            >
              <i class="far fa-ban mr-2"></i>
              Confirm Declining
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.reject.active" max-width="800px" persistent style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-power-off mr-2"></i>Reject Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.reject.valid" ref="rejectOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.reject.note"
                    title="Rejection Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="rejectOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="pink"
              :dark="validRejectNotes && !modals.reject.loading"
              :disabled="!validRejectNotes || modals.reject.loading"
              :loading="modals.reject.loading"
              @click="rejectOfferConfirmed()"
            >
              <i class="far fa-power-off mr-2"></i>
              Confirm Rejection
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modals.approve.active"
      max-width="800px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-check-double mr-2"></i>Approve Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.approve.valid" ref="approveOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.approve.note"
                    title="Approval Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="approveOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="cyan"
              :dark="validApproveNotes && !modals.approve.loading"
              :disabled="!validApproveNotes || modals.approve.loading"
              :loading="modals.approve.loading"
              @click="approveOfferConfirmed()"
            >
              <i class="far fa-check-double mr-2"></i>
              Confirm Approval
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.accept.active" max-width="800px" persistent style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-check-double mr-2"></i>Accept Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.accept.valid" ref="acceptOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.accept.note"
                    title="Acceptance Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="acceptOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="green"
              :dark="validAcceptNotes && !modals.accept.loading"
              :disabled="!validAcceptNotes || modals.accept.loading"
              :loading="modals.accept.loading"
              @click="acceptOfferConfirmed()"
            >
              <i class="far fa-check mr-2"></i>
              Confirm Acceptance
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && $has(perms.ResourceOffers.Update)">
          <div class="d-flex ml-2" v-if="!isFetching && canAccept">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  dark
                  :loading="slideouts.review.isAccepting"
                  :disabled="slideouts.review.isLoading"
                  @click="acceptOffer"
                >
                  <i class="far fa-check mr-2"></i>
                  Accept Offer
                </v-btn>
              </template>
              <span class="d-flex align-center">
                Accept sent offer and send it for management approval!
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && canDecline">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  dark
                  :loading="slideouts.review.isDeclining"
                  :disabled="slideouts.review.isLoading"
                  @click="declineOffer"
                >
                  <i class="far fa-ban mr-2"></i>
                  Decline Offer
                </v-btn>
              </template>
              <span class="d-flex align-center"> Decline offer! </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && canApprove">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                  dark
                  :loading="slideouts.review.isApproving"
                  :disabled="slideouts.review.isLoading"
                  @click="approveOffer"
                >
                  <i class="far fa-check-double mr-2"></i>
                  Approve Offer
                </v-btn>
              </template>
              <span class="d-flex align-center">
                Approve offer and proceed to shift the hours!
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && canReject">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="pink"
                  dark
                  :loading="slideouts.review.isRejecting"
                  :disabled="slideouts.review.isLoading"
                  @click="rejectOffer"
                >
                  <i class="far fa-power-off mr-2"></i>
                  Reject Offer
                </v-btn>
              </template>
              <span class="d-flex align-center"> Reject offer </span>
            </v-tooltip>
          </div>

          <v-divider vertical class="mx-2"></v-divider>
          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="slideouts.review.isLoading || isFetching"
                  @click="fetchOffer()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Offer</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import offersAPI from "../services/resource-offers-service";
import ResourceOfferState from "./ResourceOfferState.vue";
import OfferRecordCard from "./OfferRecordCard.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";

export default {
  name: "review-offer",
  components: {
    PanelSizeControl,
    UserAllSelector,
    OfferRecordCard,
    ResourceOfferState,
    RichTextEditor,
  },
  data() {
    return {
      perms: perms,
      offerId: null,
      isFetching: false,
      isUsersLoading: false,
      users: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        review: {
          tab: 0,
          active: false,
          fullWidth: false,
          isLoading: false,
          isAccepting: false,
          isDeclining: false,
          isApproving: false,
          isRejecting: false,
          isClosing: false,
        },
      },
      modals: {
        accept: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        decline: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        reject: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        approve: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.review.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
    offersUrl() {
      if (this.selected == null) return "";
      return `offers/${this.selected.id}`;
    },
    totalOfferedHours() {
      if (!this.slideouts.review.active || this.isFetching) return 0;
      else
        return this.selected.offerRecords.reduce((total, cur) => {
          return total + cur.offeredHours;
        }, 0);
    },
    canAccept() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Accept) &&
        this.selected.state == enums.ResourceOfferState.PendingTarget.value &&
        this.selected.targetUserId == this.loggedInUser.id
      );
    },
    canDecline() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Decline) &&
        this.selected.state == enums.ResourceOfferState.PendingTarget.value &&
        this.selected.targetUserId == this.loggedInUser.id
      );
    },
    canApprove() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Approve) &&
        this.managementMode &&
        this.selected.state == enums.ResourceOfferState.PendingManagement.value
      );
    },
    canReject() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Reject) &&
        this.managementMode &&
        this.selected.state == enums.ResourceOfferState.PendingManagement.value
      );
    },
    validAcceptNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.accept.note != null &&
        this.modals.accept.note.trim() != "<p></p>" &&
        this.modals.accept.note.trim() != ""
      );
    },
    validRejectNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.reject.note != null &&
        this.modals.reject.note.trim() != "<p></p>" &&
        this.modals.reject.note.trim() != ""
      );
    },
    validApproveNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.approve.note != null &&
        this.modals.approve.note.trim() != "<p></p>" &&
        this.modals.approve.note.trim() != ""
      );
    },
    validDeclineNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.decline.note != null &&
        this.modals.decline.note.trim() != "<p></p>" &&
        this.modals.decline.note.trim() != ""
      );
    },
  },
  created() {
    this.getAllUsersData();
  },
  methods: {
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    onSlideoutClosing(e) {
      //reset the changes
      this.selected = {};
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.review.active = false;
    },
    open(id) {
      this.$log(">>>>>>> open", id);
      this.offerId = id;
      this.fetchOffer();
      this.slideouts.review.active = true;
    },
    fetchOffer() {
      this.isFetching = true;
      offersAPI
        .getById(this.offerId)
        .then((resp) => {
          this.$log("getOfferById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.review.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
        })
        .catch((err) => {
          this.$log("getOfferById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    acceptOffer() {
      this.modals.accept.note = null;
      this.modals.accept.loading = false;
      this.modals.accept.active = true;
    },
    acceptOfferConfirmed() {
      this.modals.accept.loading = true;
      offersAPI
        .acceptOffer({
          id: this.selected.id,
          note: this.modals.accept.note,
        })
        .then((resp) => {
          this.modals.accept.loading = false;
          this.onAcceptOfferSuccess(this.selected.id);
          this.acceptOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Accepted!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.accept.loading = false;
          this.$dialog.notify.error("Error accepting this offer!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    acceptOfferDiscard() {
      this.modals.accept.note = null;
      this.modals.accept.loading = false;
      this.modals.accept.active = false;
    },
    onAcceptOfferSuccess(id) {
      this.$emit("accept", id);
    },
    declineOffer() {
      this.modals.decline.note = null;
      this.modals.decline.loading = false;
      this.modals.decline.active = true;
    },
    declineOfferConfirmed() {
      this.modals.decline.loading = true;
      offersAPI
        .declineOffer({
          id: this.selected.id,
          note: this.modals.decline.note,
        })
        .then((resp) => {
          this.modals.decline.loading = false;
          this.onDeclineOfferSuccess(this.selected.id);
          this.declineOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Declined!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.decline.loading = false;
          this.$dialog.notify.error("Error declining this offer!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    declineOfferDiscard() {
      this.modals.decline.note = null;
      this.modals.decline.loading = false;
      this.modals.decline.active = false;
    },
    onDeclineOfferSuccess(id) {
      this.$emit("decline", id);
    },
    approveOffer() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = true;
    },
    approveOfferConfirmed() {
      this.modals.approve.loading = true;
      offersAPI
        .approveOffer({
          id: this.selected.id,
          note: this.modals.approve.note,
        })
        .then((resp) => {
          this.modals.approve.loading = false;
          this.onApproveOfferSuccess(this.selected.id);
          this.approveOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Approved!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.approve.loading = false;
          this.$dialog.notify.error("Error approving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    approveOfferDiscard() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = false;
    },
    onApproveOfferSuccess(id) {
      this.$emit("approve", id);
    },
    rejectOffer() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = true;
    },
    rejectOfferConfirmed() {
      this.modals.reject.loading = true;
      offersAPI
        .rejectOffer({
          id: this.selected.id,
          note: this.modals.reject.note,
        })
        .then((resp) => {
          this.modals.reject.loading = false;
          this.onRejectOfferSuccess(this.selected.id);
          this.rejectOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Rejected!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.reject.loading = false;
          this.$dialog.notify.error("Error approving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    rejectOfferDiscard() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = false;
    },
    onRejectOfferSuccess(id) {
      this.$emit("reject", id);
    },
  },
};
</script>
